import React from 'react';
import Image from 'gatsby-image';
import parse from 'html-react-parser';
import { Link } from 'gatsby';

const Component = ({ item, pageId, num, latestPosts }) => {
  return (
    <section className={`latest-blogposts`} id={pageId}>
      <div className="container-fluid">
        <div className="row blog-row">
          <div className="col-12">
            <h3 className="text-center">Från bloggen</h3>
          </div>
          {latestPosts.nodes.map((item, i, arr) => (
            <article
              className="col-12 blog-wrap"
              itemScope
              itemType="http://schema.org/Article"
              key={i}
            >
              <div className="row align-items-center g-5">
                <div className="col-md-6 align-self-start image-wrap">
                  {item?.featuredImage?.node && (
                    <Image
                      fluid={
                        item?.featuredImage?.node?.localFile?.childImageSharp
                          ?.fluid
                      }
                      loading={`eager`}
                      fadeIn={false}
                      alt={item?.featuredImage?.node?.altText}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {item?.title && <h4>{item.title}</h4>}
                  {item?.excerpt && (
                    <div className="text">{parse(item.excerpt || '')}</div>
                  )}
                  <Link className="btn" to={item.uri} aria-label={item.title}>
                    Läs mer
                  </Link>
                </div>
              </div>
            </article>
          ))}

          <div className="col-12 text-center">
            <Link className="btn-large" to="/blogg/">
              Visa fler
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Component;
