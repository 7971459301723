import React from "react"
import Image from 'gatsby-image';
import parse from "html-react-parser"

const Component = ({ item, pageId, num, pageContext, arr }) => {
  const { title, text, image, align } = item;
  const firstTitle = arr[0]?.text || arr[0]?.title;
  return (
    <section className={`content`} id={pageId}>
      <div className="container-fluid">
        {title && (
          <div
            className={`row ${
              align === 'center'
                ? 'justify-content-md-center text-md-center'
                : 'justify-content-md-start'
            }`}
          >
            <div className="col-12 col-md-7 title">
              {(num === 0 || !firstTitle) && <h1>{parse(title || '')}</h1>}

              {num !== 0 && firstTitle && <h2>{parse(title || '')}</h2>}
            </div>
          </div>
        )}
        <div
          className={`row align-items-center justify-content-center ${
            align === 'center'
              ? 'justify-content-md-center text-md-center'
              : 'justify-content-md-start'
          }`}
        >
          {text && <div className="col-12 col-md-7 text">{parse(text || '')}</div>}
          {image && (
            <div className="col-10 col-md-4 image offset-md-1">
              <Image
                className="image-wrap"
                fluid={image?.localFile?.childImageSharp?.fluid}
                alt={image?.altText}
                itemProp="image"
                loading={`eager`}
                fadeIn={false}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Component
