import React from 'react';
import { graphql } from 'gatsby';
import Block from '../components/block';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PageTemplate = ({ data: { page, latestPosts, location } }) => {
  return (
    <Layout
      className={`wrap ${page.slug} page-id-${page.id}`}
      data-uri={page.uri}
      page={page}
      location={location}
    >
      <Seo seo={page.seo} title={page.title} />
      <Block
        content={page.pageData?.flexContent || []}
        title={page.title}
        latestPosts={latestPosts}
        id={page.id}
        key="content"
      />
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query page($id: String!) {
    latestPosts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { status: { eq: "publish" } }
      limit: 2
    ) {
      nodes {
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, toFormat: JPG, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        uri
        title
        excerpt
      }
    }
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      slug
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          id
          mediaDetails {
            width
            height
          }
          localFile {
            publicURL
          }
        }
        breadcrumbs {
          text
          url
        }
        schema {
          articleType
          pageType
          raw
        }
      }
      pageData {
        __typename
        flexContent {
          ... on WpPage_Pagedata_FlexContent_Top {
            fieldGroupName
            text
            color
            imageSettings {
              alignX
              alignY
            }
            webm {
              altText
              localFile {
                publicURL
              }
            }
            mp4 {
              altText
              localFile {
                publicURL
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    toFormat: JPG
                    quality: 85
                    base64Width: 460
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    toFormat: JPG
                    quality: 85
                    base64Width: 240
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Content {
            fieldGroupName
            align
            title
            text
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, toFormat: JPG, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Contacts {
            fieldGroupName
            contacts {
              name
              text
              phone {
                title
                url
                target
              }
              email
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, toFormat: JPG, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Lime {
            fieldGroupName
            formId
          }
          ... on WpPage_Pagedata_FlexContent_Iframe {
            fieldGroupName
            title
            url
            ratio
          }
          ... on WpPage_Pagedata_FlexContent_Image {
            fieldGroupName
            size
            images {
              title
              text
              link {
                target
                title
                url
              }
              imageSettings {
                alignX
                alignY
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, toFormat: JPG, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Triple {
            fieldGroupName
            bg
            title
            blocks {
              title
              text
              link {
                target
                title
                url
              }
              imageSettings {
                alignX
                alignY
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, toFormat: JPG, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              svg {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on WpPage_Pagedata_FlexContent_Faq {
            fieldGroupName
            title
            questions {
              title
              text
            }
          }
          ... on WpPage_Pagedata_FlexContent_Numbers {
            fieldGroupName
            title
            numbers {
              append
              circle
              number
              text
              decimals
              circleLength
            }
          }
        }
      }
    }
  }
`;
