import React from 'react';
import Image from 'gatsby-image';
import parse from 'html-react-parser';
import Phone from '../../../content/assets/mobile-alt.internal.svg';
import Mail from '../../../content/assets/envelope.internal.svg';

const Component = ({ item, pageId, num, pageContext, arr }) => {
  const { contacts } = item;
  return (
    <section className={`contacts`} id={pageId}>
      <div className="container-fluid">
        <div className="row contact-row">
          {contacts.map((item, i, arr) => (
            <div className="col-12 col-md-6 contact-wrap" key={i}>
              <div className="row g-5">
                <div className="col-6 col-md-4 image-wrap">
                  {item?.image && (
                    <Image
                      fluid={item?.image?.localFile?.childImageSharp?.fluid}
                      alt={item?.image?.altText}
                    />
                  )}
                </div>
                <div className="col-md-8">
                  {item?.name && <h4>{item.name}</h4>}
                  {item?.text && <div className="text">{parse(item.text || '')}</div>}
                  {item?.phone && (
                    <a href={item?.phone?.url}>
                      <Phone />
                      {item?.phone?.title}
                    </a>
                  )}
                  {item?.email && (
                    <a href={`mailto:${item?.email}`}>
                      <Mail />
                      {item?.email}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Component;
