import React, { useLayoutEffect, useState } from 'react';
import parse from 'html-react-parser';

const Component = ({ item, pageId, num }) => {
  const { formId } = item;
  const [limeForm, setLimeForm] = useState('');
  useLayoutEffect(() => {
    setLimeForm(`<lime-form form-id=${formId}></lime-form>`);
  });

  return (
    <section className={`lime`} id={pageId}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8">{parse(limeForm || '')}</div>
        </div>
      </div>
    </section>
  );
};

export default Component;
