import React from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import parse from 'html-react-parser';

const Component = ({ item, pageId, num, arr }) => {
  const { bg, blocks, title } = item;
  const backgroundColor = bg || 'transparent';
  const slanted = num !== arr.length - 1 && bg;
  return (
    <section
      className={`triple ${bg ? 'bg' : ''} ${slanted ? 'slanted' : ''}`}
      style={{ backgroundColor }}
    >
      <div className="container-fluid" id={pageId}>
        {title && (
          <div className={`row`}>
            <div className="col-12 text text-start mb-3 mb-sm-5">
              <h2>{parse(title || '')}</h2>
            </div>
          </div>
        )}
        <div className="row justify-content-center item-wrap">
          {blocks.map((item, i, arr) => (
            <div className="col-12 col-md-4 item" key={i}>
              <div className="row g-0 h-100">
                <div className="col-12">
                  {item?.image && !item?.svg && (
                    <Image
                      fluid={item?.image?.localFile?.childImageSharp?.fluid}
                      alt={item?.image?.altText}
                      loading={`eager`}
                      fadeIn={false}
                    />
                  )}

                  {item?.svg && !item?.image && (
                    <img
                      className="svg"
                      src={item.svg.localFile.publicURL}
                      alt={item.svg.altText}
                    />
                  )}

                  {item?.title && <h4>{item.title}</h4>}
                  {item?.text && <div className="text">{parse(item.text || '')}</div>}
                </div>

                {item?.link && (
                  <div className="col-12 align-self-end">
                    <Link className="btn" to={item?.link?.url}>
                      {item?.link?.title}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Component;
