import React from "react"
import Image from "gatsby-image"
import parse from 'html-react-parser'
import { Link } from 'gatsby'

const Component = ({ item, pageId, children, small }) => {
  const { size, images } = item;
  const sourcesFirst =
    images?.length > 0 && images[0]?.imageMobile
      ? [
          images[0]?.imageMobile?.localFile?.childImageSharp?.fluid,
          {
            ...images[0]?.image?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 768px)`,
          },
        ]
      : images?.length > 0 && images[0]?.image?.localFile?.childImageSharp?.fluid
      ? images[0]?.image?.localFile?.childImageSharp?.fluid
      : false;
  return (
    <section className={`image ${size}`} id={pageId}>
      {size === 'full' && images && (
        <div className="image-fullsize">
          {sourcesFirst && (
            <Image
              className="image-wrap"
              fluid={sourcesFirst}
              alt={images[0].image?.altText}
              itemProp="image"
              loading={`eager`}
              fadeIn={false}
              imgStyle={{
                objectFit: 'cover',
                objectPosition:
                  images[0].imageSettings.alignX +
                  ' ' +
                  images[0].imageSettings.alignY,
              }}
            />
          )}
          <div className="container-fluid">
            <div className="row align-content-center">
              <div className="col-12 col-md-7 title">
                {!!images[0].title && <h3>{images[0].title}</h3>}
              </div>
              <div className="col-12 col-md-7 text">
                {!!images[0].text && <p>{parse(images[0].text || '')}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
      {size === 'default' && images && (
        <div className="container-fluid">
          <div className="row">
            {images.map((item, i, arr) => {
              const sources = item?.imageMobile
                ? [
                    item?.imageMobile.localFile?.childImageSharp?.fluid,
                    {
                      ...item?.image?.localFile?.childImageSharp?.fluid,
                      media: `(min-width: 768px)`,
                    },
                  ]
                : item?.image?.localFile?.childImageSharp?.fluid;
              return (
                <div className="col-12 col-md-6" key={i}>
                  {sources && (
                    <Image
                      className="image-wrap"
                      fluid={sources}
                      alt={item.image.altText}
                      itemProp="image"
                      loading="eager"
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition:
                          item.imageSettings.alignX +
                          ' ' +
                          item.imageSettings.alignY,
                      }}
                    />
                  )}
                  {!!item.title && <h4>{item.title}</h4>}
                  {!!item.text && <>{parse(item.text || '')}</>}
                  {item?.link && (
                    <Link className="btn" to={item?.link?.url}>
                      {item?.link?.title}
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

Component.defaultProps = {
  small: false,
}

export default Component
