/* eslint-disable react/no-array-index-key */
import React from "react"
import Top from "./blocks/top"
import Content from "./blocks/content"
import Image from "./blocks/image"
import Triple from './blocks/triple'
import Contacts from './blocks/contacts'
import Lime from './blocks/lime'
import Blog from './blocks/latestBlog'
import Faq from './blocks/faq'
import Numbers from './blocks/numbers';
import Iframe from './blocks/iframe';
const Component = props => {
  const { id, content } = props

  const layouts = {
    page_Pagedata_FlexContent_Top: Top,
    page_Pagedata_FlexContent_Triple: Triple,
    page_Pagedata_FlexContent_Content: Content,
    page_Pagedata_FlexContent_Image: Image,
    page_Pagedata_FlexContent_Contacts: Contacts,
    page_Pagedata_FlexContent_Lime: Lime,
    page_Pagedata_FlexContent_Blog: Blog,
    page_Pagedata_FlexContent_Faq: Faq,
    page_Pagedata_FlexContent_Numbers: Numbers,
    page_Pagedata_FlexContent_Iframe: Iframe,
    page_default: Content,
  };

  return (
    <>
      {!!content && content.map((item, i, arr) => {
        const layoutType = item.fieldGroupName
        const ComponentTag = layouts[layoutType]
          ? layouts[layoutType]
          : layouts["page_default"]
        return (
          <ComponentTag
            item={item}
            key={id + i + layoutType}
            arr={arr}
            num={i}
            pageId={ i === 1 ? 'main' : ''}
            {...props}
          />
        )
      })}
    </>
  )
}

export default Component
