import React, { useContext } from 'react';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <button
      type="button"
      className={`accordion-button text-left ${
        isCurrentEventKey ? '' : 'collapsed'
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
};

const Component = ({ item, pageId, num, pageContext, arr }) => {
  return (
    <section className={`faq`} id={pageId}>
      <div className="container-fluid">
        {item?.title && <h3 className="text-left">{item.title}</h3>}
      </div>
      <div className="container-fluid container-fluid-main">
        <Accordion className="accordion-flush">
          {item.questions.map((item, i, arr) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
                <ContextAwareToggle
                  variant="link"
                  className="dropdown-btn"
                  eventKey={'toggle' + i}
                >
                  {item.title}
                </ContextAwareToggle>
              </h2>
              <Accordion.Collapse
                className="accordion-collapse"
                eventKey={'toggle' + i}
              >
                <div className="accordion-body">{parse(item.text || '')}</div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default Component;
