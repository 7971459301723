import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, animate } from 'framer-motion';

const Counter = ({ from, to, inView, append, decimals }) => {
  const nodeRef = useRef();
  const [seen, setSeen] = useState(false);
  useEffect(() => {
    if (!inView) return;
    setSeen(true)
  }, [inView]);
  useEffect(() => {
    if (!seen) return;
    const node = nodeRef.current;
    const controls = animate(from, to, {
      duration: 3,
      onUpdate(value) {
        node.textContent = value
          .toFixed(decimals || 0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      },
    });
    return () => controls.stop();
  }, [from, to, seen]);

  return (
    <div className="top">
      <span ref={nodeRef} />
      {!!append && <span> {append}</span>}
    </div>
  );
}

const Number = ( props ) => {
  const { text, circle, endCircle } = props
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('end');
    }
  }, [controls, inView]);

  return (
    <div
      ref={ref}
      className={`col-6 col-md-3 item ${
        !!circle ? 'circle' : 'no-circle'
      }`}
    >
      <div className="square">
        {!!circle && (
          <svg
            className="progress-icon"
            viewBox="0 0 44.216 44.216"
            style={{
              width: '160px',
              height: '160px',
              transform: 'rotate(90deg) scaleY(-1)',
            }}
          >
            <motion.path
              fill="none"
              strokeWidth="4"
              stroke="#74b842"
              strokeDasharray="0 1"
              strokeLinecap="round"
              strokeMiterlimit="10"
              animate={controls}
              initial="start"
              transition={{ duration: 3 }}
              variants={{
                end: { pathLength: endCircle || 0.8 },
                start: { pathLength: 0 },
              }}
              d="M2.113,22.113
	c0,11.046,8.954,20,20,20s20-8.954,20-20s-8.954-20-20-20S2.113,11.067,2.113,22.113"
            />
          </svg>
        )}
        <div className="text-wrap">
          <div className="text">
            <Counter inView={inView} {...props} />
            <div className="lower">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}; 

const Component = ({ item, pageId, num }) => {
  const { title } = item;

  return (
    <section className={`numbers`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          {!!item.numbers &&
            item.numbers.map((item, i, arr) => (
              <Number
                key={i}
                from={0}
                to={item.number || 0}
                append={item.append || ''}
                decimals={item.decimals || 0}
                circle={item.circle || false}
                endCircle={item.circleLength * 0.01 || 0.85}
                text={item.text || ''}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Component;
