import React from 'react';

const Component = ({ item, pageId, num }) => {
  const { title, url, ratio } = item;

  return (
    <section className={`iframe`} id={pageId}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {!!title && (
            <div class="col-12 text-center mb-5">
              <h3>{title}</h3>
            </div>
          )}
          <div className="col-12 col-md-8">
            {!!url && (
              <div class={`ratio ratio-${ratio}`}>
                <iframe src={url} title={title} allowfullscreen></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Component;
